import { Controller } from "@hotwired/stimulus";
import { closeOnOutsideClick } from "../../../components/modal";

let shouldSubmitForm = false;
export default class extends Controller {
  static targets = [
    "shortlistVisibleFilters",
    "visibileFieldButton",
    "visibiltyFieldForm",
  ];

  connect() {
    this.columnVisibilty = [];
    document.addEventListener("click", this.handleDocumentClick.bind(this));
  }

  disconnect() {
    document.removeEventListener("click", this.handleDocumentClick.bind(this));
  }

  handleDocumentClick(event) {
    closeOnOutsideClick(
      this.visibileFieldButtonTarget,
      this.shortlistVisibleFiltersTarget,
      event.target
    );

    if (!this.shortlistVisibleFiltersTarget.contains(event.target)) {
      this.updateColumnVisibility();
    }
  }

  updateColumnVisibility() {
    const form = document.getElementById("visibilty-field-form");
    const columns = this.getHeadersAsArray();
    if (form) {
      form.querySelector(`input[name='column_data']`).value =
        JSON.stringify(columns);

      if (shouldSubmitForm) {
        form.addEventListener("turbo:submit-start", () => {
          this.loadTableSkeleton(true);
        });

        form.addEventListener("turbo:submit-end", (event) => {
          setTimeout(() => {
            this.loadTableSkeleton(false);
          }, 500);
        });
        form.requestSubmit();
        shouldSubmitForm = false;
      }
    }
  }

  getHeadersAsArray() {
    const checkboxes = document.querySelectorAll(
      `.shortlist-column-visibility`
    );

    return Array.from(checkboxes)
      .filter((checkbox) => !checkbox.checked)
      .map((checkbox) => {
        return {
          column: checkbox.dataset.name,
          visibility: checkbox.checked,
        };
      });
  }

  loadTableSkeleton(showSkeleton) {
    const table = document.getElementById("shortlistTable");
    if (table && showSkeleton) {
      table.classList.add("istableloading");
    } else {
      table.classList.remove("istableloading");
    }
  }

  openVisibleFieldFilter() {
    if (!this.hasShortlistVisibleFiltersTarget) {
      return false;
    }
    this.shortlistVisibleFiltersTarget.classList.toggle("hidden");
  }

  handleChange() {
    shouldSubmitForm = true;
  }
}
