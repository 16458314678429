import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="company"
export default class extends Controller {
  connect() {}

  submitDisputeForm() {
    const form = document.getElementById("dispute-form");
    const review_id = document.getElementById("dispute_disputable_id").value;
    const type = document.getElementById("dispute_dispute_type").value;
    const msg = document.getElementById("dispute_message").value;

    const dispute_disputable_type = document.getElementById(
      "dispute_disputable_type"
    ).value;
    if (
      dispute_disputable_type != null &&
      dispute_disputable_type != "" &&
      dispute_disputable_type === "Post"
    ) {
      const element = document.getElementById(`community_post_${review_id}`);
      this.removeElementAttributes(element);
      element.querySelector("span").textContent = "Flagged";
    }

    if (form && type !== "" && msg !== "") {
      form.requestSubmit();
      form.reset();
      this.element.classList.add("hidden");
      this.element.style.display = "none";
      $("body").removeClass("modal-open-reportIssue");
      const flag_link = document.getElementById(`flag-link-${review_id}`);
      if (dispute_disputable_type === "Post") {
        if (flag_link) {
          flag_link.remove();
        }
      } else {
        if (flag_link && flag_link.firstElementChild.querySelector("span")) {
          this.removeElementAttributes(flag_link);
          flag_link.firstElementChild.querySelector("span").textContent =
            "Flagged";
        }
      }
    }
  }

  removeElementAttributes(element) {
    if (element) return false;
    element.removeAttribute("data-controller");
    element.removeAttribute("data-action");
    element.removeAttribute(`data-review-target`);
    element.removeAttribute(`data-review-key`);
    element.removeAttribute(`data-post-target`);
    element.removeAttribute(`data-post-key`);
  }

  openModal() {
    document.getElementById("reportRemarks").classList.remove("hidden");
  }
}
