import { Controller } from "@hotwired/stimulus";
import { openModal } from "../components/modal";

export default class extends Controller {
  connect() {
    this.intializeRatingYo(this.element.dataset.person);
  }

  intializeRatingYo(person_key) {
    const current_element =
      document.getElementsByClassName("__colleague_stars")[0];
    const current_rating =
      current_element && current_element.dataset.currentRating
        ? current_element.dataset.currentRating
        : 0;
    $(`.__colleague_stars`).rateYo({
      normalFill: "#C3C6CF",
      ratedFill: "#f1c40f",
      numStars: 5,
      fullStar: true,
      starWidth: "25px",
      rating: current_rating,
      onSet: function (rating) {
          const newHref = `${window.location.origin}/people/${person_key}/reviews/new?prerate=${rating}`;
          $(`.__colleague_stars_link`).attr("href", newHref);
          $(`.__colleague_stars_link`).attr("target", "_top");
      },
    });
  }
}
