export function initSelect2(element_id) {
  const elements = Array.isArray(element_id) ? element_id : [element_id];
  elements.forEach((element) => $(`#${element}`).select2());
}

export function destroySelect2(element_id) {
  const elements = Array.isArray(element_id) ? element_id : [element_id];

  const destroySelect2 = (selector) => {
    const element = $(selector);
    if (element.length && $.fn.select2 && element.data("select2")) {
      element.select2("destroy");
    }
  };

  elements.forEach((element) => {
    destroySelect2(`#${element}`);
  });
}
