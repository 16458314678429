import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="profile-review"
export default class extends Controller {
  connect() {
    jQuery("#review_duration_filter")
    .select2()
    .on("change", (e) => {
      document.getElementById("filter-form-review").requestSubmit();
    });

  }
}
