import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="portal--dashboard"
export default class extends Controller {
  connect() {
    var personslider = document.getElementById("personslider");
    if (personslider) {
      new Splide(".personslider", {
        perPage: 4.3,
        wheel: true,
        wheelSleep: true,
        autoplay: false,
        focus: "left",
        arrows: false,
        gap: "14px",
        pagination: false,
        classes: {
          prev: "splide__arrow--prev icon-chevron-left",
          next: "splide__arrow--next icon-chevron-right",
        },
        breakpoints: {
          1600: {
            perPage: 3.5,
          },
          1280: {
            perPage: 2.5,
          },
          767: {
            perPage: 2,
          },
          600: {
            perPage: 1.5,
          },
          440: {
            perPage: 1,
          },
        },
      }).mount();
    }
  }
}
