import { Controller } from "@hotwired/stimulus";
import Dropzone from "dropzone";

Dropzone.autoDiscover = false;

export default class extends Controller {
  static targets = ["dropzone", "fileInput"];
  connect() {
    this.initDropzone();
  }

  initDropzone() {
    this.dropzone = new Dropzone(this.dropzoneTarget, {
      url: "#",
      paramName: "file",
      maxFilesize: 5,
      acceptedFiles: ".csv",
      addRemoveLinks: true,
      autoProcessQueue: false,
      maxFiles: 1,
      dictDefaultMessage: `<span class="font-normal text-[14px] leading-[20px] font-body_font text-[rgba(5,18,55,0.76)]">
                        Drag and drop your CSV file here, or click to upload
                    </span> <em class="font-normal not-italic text-[12px] leading-[16px] font-body_font tracking-[0.2px] text-[rgba(5,18,55,0.60)]">Ensure the file is in .csv format (max. 5MB)</em>`,
      previewsContainer: null,
      dictRemoveFile: "",

      previewTemplate: `
        <div class="dz-preview w-full dz-file-preview">
          <div class="bg-[#fff] mt-5 flex gap-[10px] items-center w-full p-[7px_14px] rounded-[8px] border border-border_color text-[14px] leading-5 font-text_font font-normal">
            <div class="flex gap-[0] flex-col flex-1 items-start">
              <h5 class="dz-filename font-normal not-italic text-[14px] leading-[20px] font-body_font tracking-[0.2px] text-left text-[rgba(5,18,55,0.60)]" data-dz-name></h5>
              <h5 class="dz-size font-normal text-[14px] leading-4 font-text_font text-text_color" data-dz-size></h5>
            </div>
            <a class="dz-remove" href="javascript:undefined;" data-dz-remove=""><i class="anron-cross text-[18px] text-text_color ml-auto"></i></a>
          </div>
        </div>
      `
    });

    this.dropzone.on("addedfile", (file) => {
      this.setInputFile(file);
    });

    this.dropzone.on("removedfile", () => {
      this.fileInputTarget.value = null;
    });

  }

  handleDrop(event) {
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      this.fileInputTarget.files = files;
    }
  }

  setInputFile(file) {
    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(file);
    this.fileInputTarget.files = dataTransfer.files;
  }
}
