import { Controller } from "@hotwired/stimulus";
import {
  isValidEmail,
  errorMessage,
  removeErrorMessage,
  generateFileInfo,
} from "../components/validation";

const DEFAULT_IMAGE = "/images/user-avatar-wide.png";
invite_friends_array = [];
selected_profiles = [];
const EXCEED_LIMIT_MESSAGE =
  "File size exceeds the allowed limit. Please choose a smaller file.";
const FILE_TYPE_MESSAGE =
  "The file type is not allowed. Please select a different file.";
const FILE_SIZE_VALIDATION = 5000;
export default class extends Controller {
  static targets = ["profileCheckbox", "linkField", "template"];

  connect() {
    this.selectedProfiles = new Set();

    if (this.element) {
      const person_key = this.element.dataset.key;
      if (person_key == null || person_key == "undefined" || person_key == "") {
        return;
      }
      if (window.location.href.includes("congratulations")) {
        setTimeout(() => {
          window.location.href = `${window.location.origin}/people/${person_key}`;
        }, 2000);
      }
    }

    const isVideoaskMessage = (message) =>
      message.origin === "https://www.videoask.com" &&
      message.data &&
      message.data.type &&
      message.data?.type?.startsWith("videoask_");
    const videoAskMessage = (message) => {
      if (!isVideoaskMessage(message)) {
        return;
      }

      if (message?.data?.type === "videoask_question_submitted") {
        console.log(message?.data?.contactId, "videoask_contact_id");
        document.getElementById("videoask_contact_id").value =
          message?.data?.contactId;
      }
      if (message?.data?.type === "videoask_submitted") {
        setTimeout(() => {
          var form = document.getElementById("videoask__form");
          if (form) {
            form.submit();
          }
        }, 1000);
      }
    };
    window.addEventListener("message", videoAskMessage);
    return () => {
      window.removeEventListener("message", videoAskMessage);
    };
  }

  toggleUserForms() {
    const userForm = document.getElementById("__user_form");
    const starterLayout = document.getElementById("__starter_layout");
    userForm.classList.toggle("hidden");
    starterLayout.classList.toggle("hidden");
  }

  termsOfService(event) {
    event.preventDefault();
    let submitButton = event.currentTarget.form.querySelector(
      'input[type="submit"]'
    );
    submitButton.disabled = event.currentTarget.checked ? false : true;
  }

  addLinks(event) {
    event.preventDefault();

    const newField = document
      .getElementById("social_link_element")
      .cloneNode(true);
    newField.classList.add("mt-2");

    event.currentTarget.previousElementSibling.value = "";

    const plusIcon = newField.querySelector("i");
    plusIcon.classList.remove("icon-plus");
    plusIcon.classList.add("icon-trash");
    plusIcon.setAttribute("data-action", "click->applicant#removeLinks");

    document.getElementById("__applicant_links").append(newField);
  }

  removeLinks(event) {
    event.preventDefault();
    event.currentTarget.parentElement.remove();
  }

  validateAndCreateUser(event) {
    event.preventDefault();

    const firstname = document.getElementById("user_first_name");
    const lastname = document.getElementById("user_last_name");
    const email = document.getElementById("user_email");
    const social_link_error = document.getElementById("__social_link_errors");

    let is_form_validated = false;

    if (firstname.value == null || firstname.value == "") {
      errorMessage(firstname, "Please enter your first name");
      is_form_validated = false;
    } else {
      is_form_validated = true;
    }

    if (lastname.value == null || lastname.value == "") {
      errorMessage(lastname, "Please enter your last name");
      is_form_validated = false;
    } else {
      is_form_validated = true;
    }

    if (
      email.value == null ||
      email.value == "" ||
      isValidEmail(email.value) == false
    ) {
      errorMessage(email, "Please enter your email address");
      is_form_validated = false;
    } else {
      is_form_validated = true;
    }

    if (isValidEmail(email.value) == false) {
      errorMessage(email, "Please enter a valid email address");
      is_form_validated = false;
    } else {
      is_form_validated = true;
    }

    this.updateLinkData();

    const link_data = document.getElementById("link_data");
    if (
      link_data.value == null ||
      link_data.value == "" ||
      link_data.value == "[]"
    ) {
      social_link_error.textContent = "Social links are required";
      is_form_validated = false;
    } else {
      is_form_validated = true;
    }

    if (is_form_validated) {
      event.currentTarget.form.requestSubmit();
    }
  }

  resetInput(event) {
    event.currentTarget.nextElementSibling.textContent = "";
  }

  handleSearch(event) {
    event.currentTarget.form.requestSubmit();
  }

  toggleLayout(event) {
    const showLayout = event.currentTarget.dataset.layout;
    const hideLayout = event.currentTarget.dataset.hideLayout;
    document.getElementById(showLayout).classList.remove("hidden");
    document.getElementById(hideLayout).classList.add("hidden");
  }

  uploadPhoto(event) {
    event.currentTarget.nextElementSibling.click();
  }

  handleFile(event) {
    const ALLOWED_FILE_TYPES = ["jpeg", "png", "jpg"];
    const input = event.target;
    const file_uploader_error = document.getElementById(
      "__uploader_validation"
    );
    if (input.files && input.files[0]) {
      const fileInfo = generateFileInfo(input.files[0]);
      if (fileInfo.fileSizeInMB > FILE_SIZE_VALIDATION) {
        file_uploader_error.innerText = EXCEED_LIMIT_MESSAGE;
        file_uploader_error.classList.remove("hidden");
        return;
      }

      if (!ALLOWED_FILE_TYPES.includes(fileInfo.fileExtension)) {
        file_uploader_error.innerText = FILE_TYPE_MESSAGE;
        file_uploader_error.classList.remove("hidden");
        return;
      }

      file_uploader_error.classList.add("hidden");

      const url = URL.createObjectURL(input.files[0]);
      document.getElementById("avatar__preview").src = url;

      document.getElementById("imageUploadButton")?.classList?.add("hidden");
      document.getElementById("imageSaveButton")?.classList?.remove("hidden");
      document.getElementById("imageDeleteButton")?.classList?.remove("hidden");
    }
  }

  deletePhoto() {
    document.getElementById("avatar__preview").src = DEFAULT_IMAGE;
    document.getElementById("imageUploadButton")?.classList?.remove("hidden");
    document.getElementById("imageSaveButton")?.classList?.add("hidden");
    document.getElementById("imageDeleteButton")?.classList?.add("hidden");
    $("#profile_upload_form")[0]?.reset();
  }

  // references
  toggleSelectAll(event) {
    const text = event.currentTarget.innerText === "Deselect All";
    this.profileCheckboxTargets.forEach((item) => {
      item.checked = !text;
      item.classList.toggle("checked");
      this.updateSelectedProfiles(item);
    });

    const send_invite_button = document.getElementById(
      "applicant_send_invite_button"
    );
    if ((event.currentTarget.innerText = text)) {
      send_invite_button.disabled = true;
      event.currentTarget.innerText = "Select All";
    } else {
      send_invite_button.disabled = false;
      event.currentTarget.innerText = "Deselect All";
    }
  }

  updateSelectedProfiles(event) {
    const checkbox = event.target || event;
    const send_invite_button = document.getElementById(
      "applicant_send_invite_button"
    );
    const profileId = checkbox.value;
    if (checkbox.checked) {
      this.addHiddenField(profileId);
      send_invite_button.disabled = false;
      checkbox.classList.add("checked");
      this.selectedProfiles.add(profileId);
    } else {
      checkbox.classList.remove("checked");
      const totalCheckedProfile = document.querySelectorAll(".checked").length;
      if (totalCheckedProfile == 0) send_invite_button.disabled = true;
      this.removeHiddenField(profileId);
      this.selectedProfiles.delete(profileId);
    }
  }

  addHiddenField(profileId) {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = "selected_profiles[]";
    input.value = profileId;
    input.id = `selected-profile-${profileId}`;
    document.getElementById("selected-profiles-container").appendChild(input);
  }

  removeHiddenField(profileId) {
    const input = document.getElementById(`selected-profile-${profileId}`);
    if (input) {
      input.remove();
    }
  }

  search(el) {
    clearTimeout(this.timeout);
    const value = el.target.value;
    this.timeout = setTimeout(() => {
      this.getSearchResults(value);
    }, 800);
  }

  getSearchResults(query) {
    const url = new URL("/references/search", window.location.origin);
    url.searchParams.set("content", query);

    fetch(url.toString(), {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    })
      .then((response) => response.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
        setTimeout(() => {
          this.reapplyCheckedState();
        }, 100);
      });
  }

  reapplyCheckedState() {
    this.profileCheckboxTargets.forEach((checkbox) => {
      if (this.selectedProfiles.has(checkbox.value)) {
        checkbox.checked = true;
      }
    });
  }
  // end references

  toggleApplicantProfileLayouts(event) {
    const showLayout = event.currentTarget.dataset.layout;
    const hideLayout = event.currentTarget.dataset.hideLayout;

    document.getElementById("__resume_cancel_button").dataset.layout =
      hideLayout;
    document.getElementById(showLayout).classList.remove("hidden");
    document.getElementById(hideLayout).classList.add("hidden");
  }

  // verify person
  verfiyProfileLayout(event) {
    const showLayout = event.currentTarget.dataset.layout;
    const hideLayout = event.currentTarget.dataset.hideLayout;

    document.getElementById("__verfiy_profile_cancel_button").dataset.layout =
      hideLayout;

    document.getElementById(showLayout).classList.remove("hidden");
    document.getElementById(hideLayout).classList.add("hidden");

    const contact_id = event.currentTarget.dataset.contact;

    const avatar = document.getElementById(`person_avatar_${contact_id}`).src;
    const name = document.getElementById(`person_name_${contact_id}`).innerText;
    const position = document.getElementById(
      `person_position_${contact_id}`
    ).innerText;

    document.getElementById("__person_avatar").src = avatar;
    document.getElementById("__person_name").textContent = name;
    document.getElementById("__person_position").textContent = position;
    document.getElementById("__person_key").value = contact_id;
  }
  // verify person
  inviteFriend(event) {
    let value = event.currentTarget.value;
    if (value.trim() != "" && event.key === "Enter") {
      if (!isValidEmail(value)) {
        const inputElement = document.getElementById(
          "__invite_friend_email_address"
        );
        errorMessage(inputElement, "Please enter a valid email address");
        return;
      }
      removeErrorMessage(document.getElementById("element_error_message"));

      const already_invited = invite_friends_array.includes(value);

      if (already_invited) {
        const inputElement = document.getElementById(
          "__invite_friend_email_address"
        );
        errorMessage(inputElement, "Already added");
        return;
      }

      invite_friends_array.push(value);
      event.currentTarget.value = "";

      this.renderInviteFriendsListing();
    }
  }

  renderInviteFriendsListing() {
    document.getElementById(
      "__applicant_total_invites"
    ).textContent = `Invite ${invite_friends_array.length} members`;

    const element = document.getElementById(
      "__applicant_invite_friend_list_element"
    );

    if (invite_friends_array.length == 0) {
      document.getElementById("__send_invitation_btn").disabled = true;
      element.classList.add("hidden");
    } else {
      document.getElementById("__send_invitation_btn").disabled = false;
      element.classList.remove("hidden");
      this.renderEmails("__applicant_invite_friend_list");

      if (element.offsetHeight > 299) {
        const scrollDiv = document.getElementById(
          "__applicant_invite_friend_list"
        );
        scrollDiv.scrollTo({
          top: scrollDiv.scrollHeight,
          behavior: "smooth",
        });
      }
    }
  }

  sendInvitation() {
    const invite_alerts = document.getElementById("__invite_alerts");
    const send_invitation_btn = document.getElementById(
      "__send_invitation_btn"
    );

    const send_invite_button = document.getElementById(
      "applicant_send_invite_button"
    );

    if (invite_friends_array.length == 0) {
      send_invitation_btn.disabled = true;
      invite_alerts.textContent = "Please add atleast one friend";
      return;
    }

    document.getElementById("emails").value =
      JSON.stringify(invite_friends_array);

    send_invitation_btn.disabled = false;
    send_invite_button.disabled = false;
    invite_alerts.textContent = "";

    document
      .getElementById("__applicant_invite_friend")
      .classList.add("hidden");
    document
      .getElementById("__applicant_references_request")
      .classList.remove("hidden");

    document
      .getElementById("__request_a_profile_element")
      .classList.add("hidden");
    document
      .getElementById("__selected_friends_list")
      .classList.remove("hidden");

    this.renderEmails("__selected_friends_list_email_address");
  }

  renderEmails(elementsID) {
    let content = ``;
    invite_friends_array.forEach((friend) => {
      content += `<li class="list-none">
        <span class="text-[12px] leading-4 font-normal font-text_font tracking-[0.2] text-[#051237] bg-white border border-[rgba(5,18,55,0.08)] p-[5px_5px_5px_9px] rounded-[100px] flex items-center gap-[6px]"> ${friend} <i class="icon-x text-[14px] cursor-pointer" data-action="click->applicant#removeFriend"></i>
        </span>
      </li>`;
    });
    $(`#${elementsID}`).html(content);
  }

  removeFriend(event) {
    event.preventDefault();
    const email = event.currentTarget.parentElement.innerText;

    let index = invite_friends_array.indexOf(email);
    if (index !== -1) {
      invite_friends_array.splice(index, 1);
    }
    console.log(invite_friends_array);
    event.currentTarget.parentNode.parentElement.remove();
    this.renderInviteFriendsListing();

    if (invite_friends_array.length == 0) {
      document
        .getElementById("__selected_friends_list")
        .classList.add("hidden");
      document
        .getElementById("__request_a_profile_element")
        .classList.remove("hidden");
    }
  }

  validateResume(event) {
    const ALLOWED_FILE_TYPES = ["pdf", "doc", "docx"];
    const input = event.target;
    const file_uploader_error = document.getElementById("__resume_errors");
    const uploadBtn = document.getElementById("__upload_resume_button");

    if (input.files && input.files[0]) {
      const fileInfo = generateFileInfo(input.files[0]);

      if (fileInfo.fileSizeInMB > FILE_SIZE_VALIDATION) {
        file_uploader_error.innerText = EXCEED_LIMIT_MESSAGE;
        file_uploader_error.classList.remove("hidden");
        uploadBtn.classList.add(
          "disabled:opacity-50",
          "disabled:cursor-not-allowed"
        );
        uploadBtn.disabled = true;
        return;
      }

      if (!ALLOWED_FILE_TYPES.includes(fileInfo.fileExtension)) {
        file_uploader_error.innerText = FILE_TYPE_MESSAGE;
        file_uploader_error.classList.remove("hidden");
        uploadBtn.classList.add(
          "disabled:opacity-50",
          "disabled:cursor-not-allowed"
        );
        uploadBtn.disabled = true;
        return;
      }

      uploadBtn.disabled = false;
      file_uploader_error.classList.add("hidden");
      uploadBtn.classList.remove(
        "disabled:opacity-50",
        "disabled:cursor-not-allowed"
      );
    }
  }

  // social links start

  handleKeyup(e) {
    if (e.target.value != "") {
      e.target.classList.remove(
        "border-1!",
        "border-rose-400!",
        "placeholder-rose-600!"
      );
      const spanElement = e.target.nextElementSibling;

      if (spanElement && spanElement.tagName === "SPAN") {
        e.target.classList.remove("border-1!", "border-rose-400!");
        spanElement.textContent = "";
      }
    }
  }
  addLink(event) {
    const link = this.linkFieldTarget.value;

    if (link == "") {
      const spanElement = this.linkFieldTarget.nextElementSibling;
      if (spanElement && spanElement.tagName === "SPAN") {
        spanElement.textContent = "This field is required";
      }
      return;
    }

    const iconTag = document.getElementById("link_image");
    const linkType = iconTag.dataset.linkType || "link";

    const newElement = this.templateTarget.cloneNode(true);
    newElement.classList.remove("hidden");

    const img = newElement.querySelector("img");
    const input = newElement.querySelector("input");
    const iconPath = iconTag.src;
    img.src = iconPath;
    input.value = link;
    input.name = linkType;

    this.linkFieldTarget.value = "";

    const element = event?.currentTarget?.parentNode?.parentNode;
    if (this.totalSocialList() >= 3) {
      document
        .getElementById("social_dropdown_list")
        .classList.add("overflow-auto", "max-h-[160px]", "scrollbar");
    }

    if (element && element.tagName == "LI") {
      element.insertAdjacentElement("afterend", newElement);
    }

    this.updateLinkData();
  }

  updateLinkData() {
    const links = [];
    this.element.querySelectorAll("li").forEach((li) => {
      const input = li.querySelector("input");
      if (input) {
        const linkType = input.name;
        const linkUrl = input.value;
        const linkObj = {};
        linkObj["network"] = linkType;
        linkObj["url"] = linkUrl;
        if (linkType && linkUrl) {
          links.push(linkObj);
        }
      }
    });
    const linkDataField = document.getElementById("link_data");
    linkDataField.value = JSON.stringify(links);
  }

  totalSocialList() {
    const list = document.getElementById("social_dropdown_list");
    return list.children.length;
  }
  // social links end
}
