import { Controller } from "@hotwired/stimulus";
import { determine } from "jstz";

export default class extends Controller {
  static targets = [
    "searchInput",
    "form",
    "asideDrawerFilter",
    "asideDrawerModal",
    "asideDrawerChildren",
    "tab",
    "loader",
    "insightData",
    "teamData",
    "drawerSearchField",
  ];
  connect() {
    this.timeout = null;
    jQuery("#select_company_sort").off("change");
    jQuery("#select_company_sort")
      .select2()
      .on("change", (e) => {
        const form = document.getElementById("company_filters_form");
        if (form) {
          document.getElementById("company_sort_field").value = e.target.value;
          document.getElementById("update_stats").value = false;
          form.requestSubmit();
        }
      });
    jQuery("#company_country_codes").select2();

    document.addEventListener("click", (event) => {
      const drawerOpener = document.getElementById("filter-drawer-opener");
      const asideDrawer = document.querySelector(".company-filter-drawer");
      const drawerContent = document.querySelector(
        ".company-filter-drawer > div"
      );

      if (!drawerOpener || !asideDrawer || !drawerContent) {
        console.warn("Drawer elements are not found.");
        return;
      }

      const clickedOutsideDrawer =
        !drawerContent.contains(event.target) &&
        !drawerOpener.contains(event.target);
      const isDrawerVisible = asideDrawer.classList.contains("visible");

      if (isDrawerVisible && clickedOutsideDrawer) {
        this.closeDrawer();
      }
    });
  }

  disconnect() {
    const destroySelect2 = (selector) => {
      const element = $(this.element).find(selector);
      if (element.length && $.fn.select2 && element.data("select2")) {
        element.select2("destroy");
      }
    };

    destroySelect2("#select_company_sort");
    destroySelect2("#select6");
    destroySelect2("#company_country_codes");
    destroySelect2("#skill_search");
  }

  company_show_more(event) {
    this.handleShowMoreAndShowLess(event, "similar_company", 5);
    var element = document.querySelector("#similar_companies_list");
    element.scrollTo({ top: element.scrollHeight, behavior: "smooth" });
  }

  location_show_more(event) {
    this.handleShowMoreAndShowLess(event, "company_location", 4);

    var element = document.querySelector("#location_list");
    element.scrollTo({ top: element.scrollHeight, behavior: "smooth" });
  }

  handleShowMoreAndShowLess(event, targetClass, currently_show) {
    const record = $(`.${targetClass}.hidden`);

    if (event.target.textContent == "Show more") {
      const slicing = record.slice(0, currently_show);
      slicing.removeClass("hidden");

      if (record.length <= currently_show) {
        event.target.textContent = "Show less";
      }
    } else {
      $(`.${targetClass}`).each((i, el) => {
        if (i >= currently_show) {
          $(el).addClass("hidden");
        }
      });
      event.target.textContent = "Show more";
    }
  }

  search(el) {
    clearTimeout(this.timeout);
    const value = el.target.value;
    const search_dropdown = document.getElementById("companies_list");
    if (value == "") {
      search_dropdown?.firstElementChild?.classList.add("hidden");
      // this.element.requestSubmit();
      return;
    }
    this.timeout = setTimeout(() => {
      this.renderCompanies(value);
    }, 800);
  }

  renderCompanies(value) {
    fetch(`${window.location.origin}/companies/search?q=${value}`, {
      method: "GET",
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
    })
      .then((response) => response.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
      });
  }

  submitByEmployeeCount(event) {
    event.currentTarget.form.requestSubmit();
  }

  selectAndSearchCompany(event) {
    this.searchInputTarget.value = event.currentTarget.dataset.company;
    const search_dropdown = document.getElementById("companies_list");
    search_dropdown?.firstElementChild?.classList.add("hidden");
    this.element.requestSubmit();
  }

  searchCompanyEmployees(event) {
    event.preventDefault();
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.formTarget.requestSubmit();
    }, 300);
  }

  openDrawer() {
    if (this.hasSearchInputTarget && this.hasDrawerSearchFieldTarget) {
      const searchValue = this.searchInputTarget.value;
      this.drawerSearchFieldTarget.value = searchValue;
    }
    this.asideDrawerModalTarget.classList.add("visible", "opacity-100");
    this.asideDrawerChildrenTarget.classList.add("right-0!");

    document.body.style.overflow = "hidden";
    document.getElementById("update_stats").value = true;
  }

  closeDrawer() {
    const asideDrawer = this.hasAsideDrawerModalTarget
      ? this.asideDrawerModalTarget
      : document.querySelector(".company-filter-drawer");
    const drawerContent = this.hasAsideDrawerChildrenTarget
      ? this.asideDrawerChildrenTarget
      : document.querySelector(".company-filter-drawer > div");

    asideDrawer.classList.remove("visible", "opacity-100");
    drawerContent.classList.remove("right-0!");
    document.body.style.overflow = "auto";
  }

  submitDrawerForm(event) {
    this.closeDrawer();
    event.currentTarget.form.requestSubmit();
  }

  activateTab(event) {
    const clickedTab = event.currentTarget;
    this.tabTargets.forEach((tab) => {
      tab.classList.remove("active");
    });
    clickedTab.classList.add("active");
    this.showLoader();
  }

  showLoader() {
    if (this.hasTeamDataTarget) {
      this.teamDataTarget.classList.add("hidden");
    }

    if (this.hasInsightDataTarget) {
      this.insightDataTarget.classList.add("hidden");
    }
    if (this.hasloaderTarget) {
      this.loaderTarget.classList.remove("hidden");
    }
  }

  vectorSearch(event) {
    clearTimeout(this.timeout);
    const value = event.currentTarget.value;
    if (value.trim() == "") {
      return false;
    }
    this.timeout = setTimeout(() => {
      if (this.hasFormTarget) {
        const searchValue = this.searchInputTarget.value;
        this.drawerSearchFieldTarget.value = searchValue;
        document.getElementById("update_stats").value = false;
        this.formTarget.requestSubmit();
      }
    }, 400);
  }
}
