import { Controller } from "@hotwired/stimulus";
import { openModal } from "../../../components/modal";
import { initSelect2, destroySelect2} from "../../../components/select2";
export default class extends Controller {
  static targets = ["tab", "embedIframeContainer", "embedIframe", "embedHeader"]

  connect() {
    initSelect2("share_shortlist_share_sort");
    jQuery("#share_shortlist_share_sort").off("change");
    jQuery("#share_shortlist_share_sort")
      .select2()
      .on("change", (e) => {
        document.getElementById("share_shortlist_sort_field").value = e.target.value;
        this.submitShareShortlistForm();
      });
  }


  disconnect() {
    destroySelect2("share_shortlist_share_sort");
  }


  switchTab(event) {
    const clickedTab = event.currentTarget;
    this.tabTargets.forEach((tab) => {
      tab.classList.remove("active");
    });
    clickedTab.classList.add("active");
    const form = document.getElementById("search-filter-form");
    if (form) {
      document.getElementById("share_shortlist_page_view").value = clickedTab.dataset.view;
      document.getElementById("show_results_count").value = false;
      form.requestSubmit();
    }
  }

  switchEmbedTab(event){
    this.switchTab(event);
    const tab = event.currentTarget;
    if (tab && tab.dataset.view == "map") {
      this.embedHeaderTargets.forEach((header) => {
        header.classList.add("hidden");
      })
    }
    else{
      this.embedHeaderTargets.forEach((header) => {
        header.classList.remove("hidden");
      })
    }
  }

  submitShareShortlistForm() {
    const form = document.getElementById("search-filter-form");
    if (form) {
      document.getElementById("show_results_count").value = false;
      form.requestSubmit();
    }
  }

  shareShortlistInputSearch(event) {
    const input = event.currentTarget.value;
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      document.getElementById("share_shortlist_page_search").value = input;
      this.submitShareShortlistForm();
    }, 1000);
  }

  openShareListPopup() {
    openModal("share-shortlist-popup");
  }

  openShareFilter(){
    document.getElementById("show_results_count").value = true;
  }
}
