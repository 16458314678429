import { Controller } from "@hotwired/stimulus";

let contactId = null;
let personKey = null;
let iconsArray = [];
let url_regex =
  /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;
export default class extends Controller {
  static targets = ["contact", "obfuscatedDetail", "fullDetail", "details"];

  connect() {}

  toggle(event) {
    const menu = event.currentTarget.nextElementSibling;
    $(".edit-popup-dropdown").removeClass("sub-menu-show");
    menu.classList.toggle("hidden");
  }

  addMoreDetail() {
    const newElement = this.contactTarget.cloneNode(true);
    if (
      newElement.classList.contains("border-2!") &&
      newElement.classList.contains("border-rose-500!")
    ) {
      newElement.classList.remove("border-2!", "border-rose-500!");
    }

    newElement.lastElementChild.classList.remove("hidden");
    if (this.contactTarget) {
      newElement.firstElementChild.value = "";
      this.contactTarget.parentNode.append(newElement);
    } else {
      this.element.appendChild(newElement);
    }
  }

  removeNode(event) {
    if (
      event.currentTarget.parentElement?.nextElementSibling?.tagName === "SPAN"
    ) {
      event.currentTarget.parentElement.nextElementSibling.remove();
    }

    event.currentTarget.parentElement.remove();
  }

  errorMessage(element, error_message) {
    let spanElement = element.nextElementSibling;

    if (!spanElement || spanElement.tagName !== "SPAN") {
      spanElement = document.createElement("span");
      spanElement.classList.add(
        "text-rose-500!",
        "font-semibold",
        "text-sm",
        "mt-1",
        "error_messages"
      );
      element.insertAdjacentElement("afterend", spanElement);
    }

    element.classList.add("border-2!", "border-rose-500!");
    spanElement.textContent = error_message;
  }

  handleKeyUp(event) {
    event.preventDefault();

    const contactValue = event.target.value;
    const contactType =
      event.currentTarget.nextElementSibling.querySelector("select").value;
    const submitButton = document.getElementsByClassName("submit-button")[0];
    const elementParentNode = event.target.parentNode;

    if (contactValue == "") {
      event.target.parentElement.nextElementSibling.remove();
      elementParentNode.classList.remove("border-2!", "border-rose-500!");
      return;
    }

    if (contactType === "Phone") {
      const phoneRegex = /^\+?[0-9()-]*$/;
      if (!phoneRegex.test(contactValue)) {
        event.target.value = contactValue.replace(/[^\d()+-]/g, "");
        this.errorMessage(elementParentNode, "Invalid phone number");
        submitButton.disabled = true;
        return;
      }
    }

    if (contactType === "Email") {
      // Validate email address
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(contactValue)) {
        this.errorMessage(elementParentNode, "Invalid email address");
        submitButton.disabled = true;
        return;
      }
    }

    if (contactType === "URL") {
      // Validate URL
      if (!url_regex.test(contactValue)) {
        this.errorMessage(elementParentNode, "Invalid URL");
        submitButton.disabled = true;
        return;
      }
    }

    if (
      event?.currentTarget?.parentElement &&
      event?.currentTarget?.parentElement?.nextElementSibling &&
      event?.currentTarget?.parentElement?.nextElementSibling?.tagName == "SPAN"
    ) {
      event.currentTarget.parentElement.nextElementSibling.remove();
    }

    elementParentNode?.classList?.remove("border-2!", "border-rose-500!");
    submitButton.disabled = false;
  }

  checkContactType(event) {
    const contactType = event.target.value;
    const submitButton = document.getElementsByClassName("submit-button");

    const elementParentNode =
      event.currentTarget.parentElement.parentNode.parentNode;
    const contactValue =
      elementParentNode?.parentElement?.previousElementSibling.value;

    if (contactType === "Phone") {
      const phoneRegex = /^\+?[0-9()-]*$/;
      if (!phoneRegex.test(contactValue)) {
        event.target.value = contactValue.replace(/[^\d()+-]/g, "");
        this.errorMessage(elementParentNode, "Invalid phone number");
        submitButton.disabled = true;
        return;
      }
      if (contactValue.length > 13 || contactValue.length < 10) {
        this.errorMessage(
          elementParentNode,
          "Phone number should be between 10 and 13 digits"
        );
        submitButton.disabled = true;
        return;
      }
    }

    if (contactType === "Email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(contactValue)) {
        this.errorMessage(elementParentNode, "Invalid email address");
        submitButton.disabled = true;
        return;
      }
    }

    if (contactType === "URL") {
      if (!url_regex.test(contactValue)) {
        this.errorMessage(elementParentNode, "Invalid URL");
        submitButton.disabled = true;
        return;
      }
    }
    elementParentNode?.nextElementSibling?.remove();
    elementParentNode?.classList?.remove("border-2!", "border-rose-500!");
    submitButton.disabled = false;
  }

  setCardId(event) {
    contactId = event?.target?.dataset?.contact_id;
    personKey = event?.target?.dataset?.person_key;
  }

  deleteContactCard() {
    if (contactId == null && personKey == null) {
      return;
    }
    fetch(`${window.location.origin}/contact_sources/${contactId}`, {
      method: "Delete",
      headers: {
        Accept: "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
    })
      .then((response) => {
        if (response.ok) {
          const contactCard = document.querySelector(`.card-${contactId}`);
          if (contactCard) {
            contactCard.remove();
          }

          const deleteButton = document.getElementById("delete-contact");
          if (deleteButton && deleteButton.style) {
            deleteButton.style.display = "none";
          }
          Turbo.visit(`/people/${personKey}`);
        } else {
          console.error("Failed to delete the contact.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  validateUrl(event) {
    const contactValue = event.target.value;

    const elementNode = event.target;

    if (contactValue == "") {
      event?.target?.nextElementSibling?.remove();
      event?.target?.classList.remove("border-2!", "border-rose-500!");
      $(".submit-save-btn").attr("disabled", true);
      return;
    }

    if (!url_regex.test(contactValue)) {
      this.errorMessage(elementNode, `Invalid URL ${contactValue}`);
      $(".submit-save-btn").attr("disabled", true);
      return;
    }

    elementNode?.nextElementSibling?.remove();
    elementNode.classList.remove("border-2!", "border-rose-500!");
    $(".submit-save-btn").attr("disabled", false);
  }

  async loadIcons() {
    try {
      const response = await fetch(
        `${window.location.origin}/iconpicker-1.5.0.json`
      );
      this.icons = await response.json();
      iconsArray = Object.values(this.icons);
      this.renderIcons(iconsArray);
    } catch (error) {
      console.error("Error loading icons:", error);
    }
  }

  setIcon() {
    this.loadIcons();
    $("#icon-picker").removeClass("hidden");
  }

  renderIcons(iconsArray) {
    let icon = ``;
    for (let i = 0; i < iconsArray.length; i++) {
      icon += `<em class="w-[40px] h-[40px] border border-border_color rounded-sm cursor-pointer flex justify-center items-center" data-icon="${iconsArray[i]}" data-controller="contact-source" data-action="click->contact-source#selectIcon">
        <i data-search="${iconsArray[i]}" data-class="${iconsArray[i]}" class="${iconsArray[i]}"></i>
      </em>`;
    }
    $("#icon-picker-listing").html(icon);
  }

  selectIcon(event) {
    const icon = event.currentTarget.dataset.icon;
    // document.getElementsByClassName("icon_name")[0].value = icon;
    $(".icon_name").val(icon);

    const iconButton = $(".icon_button");
    iconButton.find("svg").remove();
    iconButton.html(
      `<i class="${icon}"></i> <i class="icon-chevron-down w-[18px] h-[18px] text-text_light_color"></i>`
    );
    $("#icon-picker").addClass("hidden");
  }

  searchIcons(event) {
    clearTimeout(this.timeout);
    const value = event.target.value;

    this.timeout = setTimeout(() => {
      this.search(value);
    }, 800);
  }

  search(value) {
    console.log(value);

    const items = iconsArray.filter((item) =>
      item.toLowerCase().includes(value.toLowerCase())
    );
    console.log(items);
    this.renderIcons(items);
  }

  toggleContactDetail() {
    this.obfuscatedDetailTargets.forEach((el, index) => {
      el.classList.toggle("hidden");
      this.fullDetailTargets[index].classList.toggle("hidden");
    });
    this.toggleButtonText();
  }

  toggleButtonText() {
    const button = this.element.querySelector("button[data-action]");
    const isHidden =
      this.obfuscatedDetailTargets[0].classList.contains("hidden");
    button.textContent = isHidden ? "Hide Details" : "Show Details";
  }

  hideModals(event) {
    const modal_id = event.currentTarget.dataset.modal;
    const modal = document.getElementById(modal_id);
    if (modal) {
      modal.style.display = "none";
    }
  }

  showCustomCode(event) {
    const modal = document.getElementById("embeded-frame");
    if (modal) {
      modal.classList.remove("hidden");
      modal.style.display = "block";
    }
  }
}
