import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="custom-dropdown"
export default class extends Controller {
  connect() {
    this.initDropDowns();
  }

  initDropDowns() {
    document.addEventListener("DOMContentLoaded", () => {
      const dropdownButtons = document.querySelectorAll(
        "[data-dropdown-toggle]"
      );

      dropdownButtons.forEach((button) => {
        const menuId = button.getAttribute("data-dropdown-toggle");
        const dropdownMenu = document.getElementById(menuId);

        if (!dropdownMenu) {
          console.warn(
            `Dropdown menu with ID "${menuId}" not found for button:`,
            button
          );
          return;
        }

        // Append the dropdown menu to the body
        document.body.appendChild(dropdownMenu);

        button.addEventListener("click", (e) => {
          e.stopPropagation(); // Prevent event propagation

          // Check if the dropdown is already open
          const isAlreadyOpen = !dropdownMenu.classList.contains("hidden");

          // Hide all other dropdowns
          document
            .querySelectorAll("[data-dropdown-toggle]")
            .forEach((otherButton) => {
              const otherMenuId = otherButton.getAttribute(
                "data-dropdown-toggle"
              );
              const otherMenu = document.getElementById(otherMenuId);
              if (otherMenu && otherMenu !== dropdownMenu) {
                otherMenu.classList.add("hidden");
              }
            });

          // Toggle the clicked dropdown
          if (!isAlreadyOpen) {
            dropdownMenu.classList.remove("hidden");

            // Force reflow to ensure accurate dimensions
            dropdownMenu.style.position = "absolute"; // Required for reflow
            dropdownMenu.style.top = "0"; // Temporarily set a default position
            dropdownMenu.style.left = "0";
            dropdownMenu.style.visibility = "hidden"; // Hide during measurement
            dropdownMenu.offsetHeight; // Trigger reflow by accessing the height

            // Update position after measurement
            updateDropdownPosition(button, dropdownMenu);

            // Restore visibility
            dropdownMenu.style.visibility = "";
          } else {
            dropdownMenu.classList.add("hidden");
          }
        });

        // Recalculate the dropdown position on window scroll
        window.addEventListener("scroll", () => {
          const isMenuVisible = !dropdownMenu.classList.contains("hidden");
          if (isMenuVisible) {
            updateDropdownPosition(button, dropdownMenu);
          }
        });
      });

      // Close dropdowns when clicking outside
      document.addEventListener("click", () => {
        document
          .querySelectorAll("[data-dropdown-toggle]")
          .forEach((button) => {
            const menuId = button.getAttribute("data-dropdown-toggle");
            const dropdownMenu = document.getElementById(menuId);
            if (dropdownMenu) {
              dropdownMenu.classList.add("hidden");
            }
          });
      });

      // Function to update the dropdown's position dynamically
      function updateDropdownPosition(button, dropdownMenu) {
        const buttonRect = button.getBoundingClientRect();
        const menuRect = dropdownMenu.getBoundingClientRect();
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        let top = buttonRect.bottom + window.scrollY + 10; // Default position: below the button
        let left = buttonRect.left + window.scrollX - 10;

        // Adjust position if dropdown overflows viewport boundaries
        if (menuRect.width > viewportWidth - buttonRect.left) {
          left = viewportWidth - menuRect.width - 10; // Move left to fit within the viewport
        }
        if (menuRect.height > viewportHeight - buttonRect.bottom) {
          top = buttonRect.top + window.scrollY - menuRect.height - 10; // Open upwards if necessary
        }
        if (top < 0) top = 10; // Ensure it doesn't go above the viewport
        if (left < 0) left = 10; // Ensure it doesn't go off the left edge

        // Apply calculated position
        dropdownMenu.style.top = `${top}px`;
        dropdownMenu.style.left = `${left}px`;
        dropdownMenu.style.position = "absolute";
        dropdownMenu.style.zIndex = 1000;
      }
    });
  }
}
