import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  openEditModal(event) {
    const certificationId = event.currentTarget.dataset.certificationId;
    fetch(`/certifications/${certificationId}`)
      .then((response) => response.json())
      .then((certificationData) => {
        this.populateEditFormModal(certificationData);
      })
      .catch((error) => {
        console.error("Error fetching certification data:", error);
      });
  }

  populateEditFormModal(certificationData) {
    const editModal = document.getElementById("edit-credentials");
    const form = editModal.querySelector("form");

    // Clean up existing hidden input
    const existingInput = form.querySelector('input[name="certification[id]"]');
    if (existingInput) existingInput.remove();

    const certificationIdInput = document.createElement("input");
    certificationIdInput.type = "hidden";
    certificationIdInput.name = "certification[id]";
    certificationIdInput.value = certificationData.id;
    form.appendChild(certificationIdInput);

    // Populate form fields with certification data
    form.querySelector("#certification_title").value = certificationData.title;
    form.querySelector("#certification_subtitle").value =
      certificationData.subtitle;
    form.querySelector("#profile_certifications_attributes_issue_month").value =
      certificationData.issue_month;
    form.querySelector("#certification_issue_year").value =
      certificationData.issue_year;
    form.querySelector("#certification_credential_id").value =
      certificationData.credential_id;
    form.querySelector("#certification_credential_url").value =
      certificationData.credential_url;

    const expiryMonthField = form.querySelector(
      "#profile_certifications_attributes_expiry_month"
    );
    const expiryYearField = form.querySelector(
      "#profile_certifications_attributes_expiry_year"
    );
    expiryMonthField.value = certificationData.expiry_month;
    expiryYearField.value = certificationData.expiry_year;

    const checkbox = form.querySelector("#certification_no_expiry");
    checkbox.checked = certificationData.no_expiry;

    expiryMonthField.disabled = certificationData.no_expiry;
    expiryYearField.disabled = certificationData.no_expiry;

    editModal.classList.remove("hidden");
  }

  openDeleteModal(event) {
    const certificationId = event.currentTarget.dataset.certificationId;
    fetch(`/certifications/${certificationId}`)
      .then((response) => response.json())
      .then((certificationData) => {
        this.populateDeleteModal(certificationData);
      })
      .catch((error) => {
        console.error("Error fetching certification data:", error);
      });
  }

  populateDeleteModal(certificationData) {
    const modal = document.getElementById("delete-verification-credentials");
    modal.classList.remove("hidden");
    modal.dataset.certificationData = JSON.stringify(certificationData);
  }

  close() {
    const modal = document.getElementById("delete-verification-credentials");
    modal.classList.add("hidden");
  }

  deleteCertification() {
    const modal = document.getElementById("delete-verification-credentials");
    const certificationData = JSON.parse(modal.dataset.certificationData);
    const certificationId = certificationData.id;
    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");

    fetch(`/certifications/${certificationId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    })
      .then((response) => {
        if (response.ok) {
          const listItem = document
            .querySelector(`[data-certification-id="${certificationId}"]`)
            .closest("li");
          if (listItem) {
            listItem.remove();
          }
          this.close();
        } else {
          console.error("Failed to delete certification:", response.statusText);
        }
      })
      .catch((error) => {
        console.error("Error deleting certification:", error);
      });
  }
}
