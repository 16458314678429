import { Controller } from "@hotwired/stimulus";

USER_BIO_MAX_LENGTH = 360;

export default class extends Controller {
  static targets = [
    "textarea",
    "counter",
    "addressInput",
    "countrySelect",
    "citySelect",
    "zipcodeInput",
    "previewContainer",
    "uploadInput",
    "deleteButton",
    "avatarInput",
    "readMoreLink",
    "description",
    "betterContactEmail",
    "betterContactPhone",
    "contactEmailShowAction",
    "contactEmailHiddenAction",
    "contactPhoneShowAction",
    "contactPhoneHiddenAction",
  ];

  connect() {
    this.textareaTargets.forEach((textarea) => {
      textarea.addEventListener("input", () => {
        this.updateCounter(textarea);
      });
      this.updateCounter(textarea);
    });
    setTimeout(() => {
      if (typeof google !== "undefined" && this.hasAddressInputTarget) {
        this.autocomplete = new google.maps.places.Autocomplete(
          this.addressInputTarget
        );
        this.autocomplete.addListener(
          "place_changed",
          this.populateLocation.bind(this)
        );
      }
    }, 1000);
  }

  updateCounter(textarea) {
    const maxLength = parseInt(textarea.getAttribute("maxlength")) || 500;
    const remaining = maxLength - textarea.value.length;
    const counter = this.findCounterForTextarea(textarea);
    if (counter) {
      counter.textContent = `${remaining} characters remaining`;
      if (remaining <= 0) {
        textarea.value = textarea.value.substring(0, maxLength);
        counter.textContent = "0 characters remaining";
      }
    }
  }

  findCounterForTextarea(textarea) {
    const parent = textarea.parentNode;
    return parent.querySelector('[data-profile-target="counter"]');
  }

  populateLocation() {
    const place = this.autocomplete.getPlace();
    console.log(place, "place");

    if (!place.geometry) {
      console.error("No location data for this place");
      return;
    }

    let country = "";
    let state = "";
    let zipcode = "";
    let city = "";

    // Extract country, state, and zip code from address components
    place.address_components.forEach((component) => {
      if (component.types.includes("country")) {
        country = component.short_name;
      } else if (component.types.includes("administrative_area_level_1")) {
        state = component.long_name;
      } else if (component.types.includes("postal_code")) {
        zipcode = component.long_name;
      } else if (component.types.includes("locality")) {
        city = component.short_name;
      }
    });

    this.countrySelectTarget.value = country;
    this.citySelectTarget.value = city + ", " + state;
    this.zipcodeInputTarget.value = zipcode;

    document.getElementById("latitudeInput").value =
      place.geometry.location.lat();
    document.getElementById("longitudeInput").value =
      place.geometry.location.lng();
  }

  handleLocationDetails(data) {
    // Handle the location details received from the server
    console.log(data);
  }

  handleError(error) {
    console.error("Error:", error);
    // Handle error response, e.g., display an error message to the user
  }

  preview(event) {
    const input = event.target;
    const previewContainer = this.previewContainerTarget;
    const deleteButton = document.querySelector(".delete-button");

    if (input.files && input.files[0]) {
      const reader = new FileReader();

      reader.onload = function (e) {
        const previewImage = document.createElement("img");
        previewImage.classList.add("preview-image");
        previewImage.src = e.target.result;

        const existingImage = document.querySelector(".prev-image");
        if (existingImage) {
          existingImage.classList.add("hidden");
        }

        previewContainer.appendChild(previewImage);
        deleteButton.classList.remove("hidden");
        previewImage.classList.add("disable");
        // input.disabled = true;
      };

      reader.readAsDataURL(input.files[0]);
    }
  }

  deleteImage(event) {
    event.preventDefault();
    const previewContainer = document.querySelector(".preview-image");
    const deleteButton = document.querySelector(".delete-button");
    const existingImage = document.querySelector(".prev-image");

    if (existingImage) {
      existingImage.classList.remove("hidden");
    }

    if (previewContainer) {
      previewContainer.innerHTML = "";
      previewContainer.parentNode.removeChild(previewContainer);
    }

    const input = document.getElementById("prev-image");
    if (input) {
      input.disabled = false;
    }

    deleteButton.classList.add("hidden");
    previewContainer.classList.remove("hidden");
  }

  truncateText(text, length) {
    if (text.length <= length) {
      return text;
    }
    return text.substring(0, length) + "...";
  }

  toggleReadMore() {
    const currentText = this.readMoreLinkTarget.textContent.trim();
    const fullDescription =
      this.readMoreLinkTarget.dataset.fullDescription.trim();

    if (currentText === "read more") {
      this.readMoreLinkTarget.textContent = "show less";
      this.descriptionTarget.textContent = fullDescription;
    } else {
      this.readMoreLinkTarget.textContent = "read more";
      this.descriptionTarget.textContent = this.truncateText(
        fullDescription,
        100
      );
    }
  }

  show_more_skills(event) {
    if (event.target.textContent == "Show all") {
      $(".profile-skill").removeClass("hidden");
      event.target.textContent = "Show less";
    } else {
      $(`.profile-skill`).each((i, el) => {
        if (i >= 5) {
          $(el).addClass("hidden");
        }
      });
      event.target.textContent = "Show all";
    }
  }

  toggleExperienceModal() {}

  toggleProfileDetails(event) {
    event.preventDefault();

    if (event.currentTarget.dataset.type == "email") {
      if (this.betterContactEmailTarget.classList.contains("blur-xs")) {
        event.currentTarget.parentNode.requestSubmit();
        this.contactEmailShowActionTarget.classList.add("hidden");
        this.contactEmailHiddenActionTarget.classList.remove("hidden");
      } else {
        this.betterContactEmailTarget.classList.add("blur-xs");
        this.betterContactEmailTarget.textContent = "us****@****om";
        this.contactEmailShowActionTarget.classList.remove("hidden");
        this.contactEmailHiddenActionTarget.classList.add("hidden");
      }
    } else {
      if (this.betterContactPhoneTarget.classList.contains("blur-xs")) {
        event.currentTarget.parentNode.requestSubmit();
        this.contactPhoneShowActionTarget.classList.add("hidden");
        this.contactPhoneHiddenActionTarget.classList.remove("hidden");
      } else {
        this.betterContactPhoneTarget.classList.add("blur-xs");
        this.betterContactPhoneTarget.textContent = "000000000000";
        this.betterContactPhoneTarget.classList.remove(
          "bg-[#05123752]",
          "rounded-[12px]",
          "px-2"
        );

        this.contactPhoneShowActionTarget.classList.remove("hidden");
        this.contactPhoneHiddenActionTarget.classList.add("hidden");
      }
    }
  }
}
