import { Controller } from "@hotwired/stimulus";

const FILE_SIZE_VALIDATION = 5000;
const ALLOWED_FILE_TYPES = ["jpeg", "png", "jpg"];
const DEFAULT_IMAGE = "/images/image-placeholder.png";

export default class extends Controller {
  static targets = [
    "uploaderModal",
    "videoAskModal",
    "previewVideoAskModal",
    "imageUploadModal",
    "imagePreview",
  ];
  connect() {
    const isVideoaskMessage = (message) =>
      message.origin === "https://www.videoask.com" &&
      message.data &&
      message.data.type &&
      message.data?.type?.startsWith("videoask_");
    const videoAskMessage = (message) => {
      if (!isVideoaskMessage(message)) {
        return;
      }

      if (message?.data?.type === "videoask_question_submitted") {
        document.getElementById("videoask_contact_id").value =
          message?.data?.contactId;
      }
      if (message?.data?.type === "videoask_submitted") {
        setTimeout(() => {
          var form = document.getElementById("videoask__form");
          if (form) {
            form.submit();
          }
        }, 1000);
      }
    };
    window.addEventListener("message", videoAskMessage);
    return () => {
      window.removeEventListener("message", videoAskMessage);
    };
  }

  showVideoAsk(event) {
    event.currentTarget.parentElement.requestSubmit();
  }

  uploaderModal() {
    this.uploaderModalTarget.classList.remove("hidden");
  }

  openVideoAskModal() {
    this.videoAskModalTarget.classList.remove("hidden");
    this.hideUploaderModal();
  }

  openImageUploadModal() {
    this.imageUploadModalTarget.classList.remove("hidden");
    this.hideUploaderModal();
    document.getElementById("pf_image_error_message")?.remove();
    this.deletePhoto();
  }

  hideUploaderModal() {
    this.uploaderModalTarget.classList.add("hidden");
  }

  uploadPhoto(event) {
    event.currentTarget.nextElementSibling.click();
  }

  deletePhoto() {
    this.imagePreviewTarget.src = DEFAULT_IMAGE;
    document.getElementById("imageUploadButton")?.classList?.remove("hidden!");
    document.getElementById("imageSaveButton")?.classList?.add("hidden!");
    document.getElementById("imageDeleteButton")?.classList?.add("hidden");
    $(".edit_profile")[0]?.reset();
  }

  handleFile(event) {
    const EXCEED_LIMIT_MESSAGE =
      "Upload failed: The file size exceeds the allowed limit";
    const FILE_TYPE_MESSAGE = "Upload failed: The file type is not allowed";
    const input = event.target;
    if (input.files && input.files[0]) {
      const fileInfo = this.generateFileInfo(input.files[0]);

      if (fileInfo.fileSizeInMB > FILE_SIZE_VALIDATION) {
        this.generateError(
          event.currentTarget.parentElement.parentElement,
          EXCEED_LIMIT_MESSAGE,
          false
        );
        return;
      }

      if (!ALLOWED_FILE_TYPES.includes(fileInfo.fileExtension)) {
        this.generateError(
          event.currentTarget.parentElement.parentElement,
          FILE_TYPE_MESSAGE,
          false
        );
        return;
      }

      document.getElementById("pf_image_error_message")?.remove();
      const url = URL.createObjectURL(input.files[0]);
      this.imagePreviewTarget.src = url;

      document.getElementById("imageUploadButton")?.classList?.add("hidden!");
      document.getElementById("imageSaveButton")?.classList?.remove("hidden!");
      document.getElementById("imageDeleteButton")?.classList?.remove("hidden");
    }
  }

  generateError(element, message, isremoved = false) {
    const errdiv = document.getElementById("pf_image_error_message");
    if (!errdiv) {
      const error = document.createElement("div");
      error.setAttribute("id", "pf_image_error_message");
      error.className =
        "flex items-start justify-start p-[15px] gap-2 bg-red-100 rounded-[12px] mt-5 text-white";
      error.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="fill-current w-4 h-4 mt-1 flex-no-shrink text-rose-500">
          <title id="f1yx75u7nv0lvn6ck4z10w7lksjmk2f">Icons/close outline</title>
          <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm1.41-1.41A8 8 0 1 0 15.66 4.34 8 8 0 0 0 4.34 15.66zm9.9-8.49L11.41 10l2.83 2.83-1.41 1.41L10 11.41l-2.83 2.83-1.41-1.41L8.59 10 5.76 7.17l1.41-1.41L10 8.59l2.83-2.83 1.41 1.41z"></path>
        </svg>
        <h4 class="text-sm tracking font-text_font font-medium text-[14px] leading-6 text-rose-500"> ${message} </h4>`;

      element.insertAdjacentElement("afterend", error);
    }
    if (isremoved) {
      document.getElementById("error_message").remove();
    }
  }

  generateFileInfo(file) {
    if (!file) return;

    const fileSizeInBytes = file.size;
    const fileSizeInMB = fileSizeInBytes / 1024;
    const fileName = file.name;
    const fileExtension = fileName
      .substring(fileName.lastIndexOf(".") + 1)
      .toLowerCase();

    return {
      fileName: fileName,
      fileSizeInMB: fileSizeInMB,
      fileExtension: fileExtension,
      fileSizeInBytes: fileSizeInBytes,
    };
  }

  hideModal() {
    document.getElementById("videoask_frame").innerHTML = "";
  }

  changeProfileImage(event) {
    const image = event.currentTarget
      .querySelector(".profile_image")
      .getAttribute("src");

    console.log("first");
  }
}
