import { Controller } from "@hotwired/stimulus";

let is_form_submit = true;
export default class extends Controller {
  static values = {
    jobTitle: Array,
    skills: Array,
    companyTitle: Array,
    universityFilter: Array,
    degreeFilter: Array,
    industryFilter: Array,
    stateFilter: Array,
    fullName: String,
    companyFullName: String,
    companyLocation: Array,
  };

  connect() {
    this.timeout = null;
    const filters = [
      "country_filter",
      "language_filter",
      "company_filter",
      "company_title",
      "university_filter",
      "job_title_filter",
      "degree_filter",
      "industry_filter",
      "state_filter",
      "skill_search",
    ];

    filters.forEach((filter) => $(`#${filter}`).select2());
    this.select2tags();

    const commonSelect2Config = {
      ajax: {
        delay: 250,
        dataType: "json",
        cache: false,
      },
      multiple: true,
      placeholder: "Select an option",
    };

    const createSelect2Config = (url, type) => ({
      ...commonSelect2Config,
      ajax: {
        ...commonSelect2Config.ajax,
        url,
        data: (params) => ({
          q: params.term,
          ...(type && { type }),
        }),
        processResults: (data) => {
          return {
            results: data.map((item) => {
              return { id: item[0], text: item[0] };
            }),
          };
        },
      },
    });

    $("#skill_search").select2(createSelect2Config("/search/skills_search"));

    $("#company_title").select2(
      createSelect2Config("/search/facet_search", "company_title")
    );

    $("#university_filter").select2(
      createSelect2Config("/search/facet_search", "schools")
    );

    $("#job_title_filter").select2(
      createSelect2Config("/search/facet_search", "position_title")
    );

    $("#degree_filter").select2(
      createSelect2Config("/search/facet_search", "degrees")
    );

    $("#industry_filter").select2(
      createSelect2Config("/companies/facet_search", "industry")
    );

    $("#state_filter").select2(
      createSelect2Config("/search/facet_search", "state_name")
    );

    if (this.jobTitleValue.length > 0) {
      const jobTitleFilter = $("#job_title_filter");

      this.jobTitleValue.forEach((jobTitle) => {
        if (
          jobTitleFilter.find("option[value='" + jobTitle + "']").length === 0
        ) {
          const newOption = new Option(jobTitle, jobTitle, true, true);
          jobTitleFilter.append(newOption).trigger("change");
        }
      });
    }

    if (this.skillsValue.length > 0) {
      const skillFilter = $("#skill_search");
      this.skillsValue.forEach((skill) => {
        if (skillFilter.find("option[value='" + skill + "']").length === 0) {
          const newOption = new Option(skill, skill, true, true);
          skillFilter.append(newOption).trigger("change");
        }
      });
    }

    if (this.companyTitleValue.length > 0) {
      const companyTitleFilter = $("#company_title");
      this.companyTitleValue.forEach((companyTitle) => {
        if (
          companyTitleFilter.find("option[value='" + companyTitle + "']")
            .length === 0
        ) {
          const newOption = new Option(companyTitle, companyTitle, true, true);
          companyTitleFilter.append(newOption).trigger("change");
        }
      });
    }

    if (this.universityFilterValue.length > 0) {
      const universityFilter = $("#university_filter");
      this.universityFilterValue.forEach((university) => {
        if (
          universityFilter.find("option[value='" + university + "']").length ===
          0
        ) {
          const newOption = new Option(university, university, true, true);
          universityFilter.append(newOption).trigger("change");
        }
      });
    }

    if (this.degreeFilterValue.length > 0) {
      const degreeFilter = $("#degree_filter");
      this.degreeFilterValue.forEach((degree) => {
        if (degreeFilter.find("option[value='" + degree + "']").length === 0) {
          const newOption = new Option(degree, degree, true, true);
          degreeFilter.append(newOption).trigger("change");
        }
      });
    }

    if (this.industryFilterValue.length > 0) {
      const industryFilter = $("#industry_filter");
      this.industryFilterValue.forEach((industry) => {
        if (
          industryFilter.find("option[value='" + industry + "']").length === 0
        ) {
          const newOption = new Option(industry, industry, true, true);
          industryFilter.append(newOption).trigger("change");
        }
      });
    }

    if (this.stateFilterValue.length > 0) {
      const stateFilter = $("#state_filter");
      this.stateFilterValue.forEach((state) => {
        if (stateFilter.find("option[value='" + state + "']").length === 0) {
          const newOption = new Option(state, state, true, true);
          stateFilter.append(newOption).trigger("change");
        }
      });
    }

    if (this.companyLocationValue.length > 0) {
      this.submitFormById("company-filters-form");
    }

    if (this.fullNameValue != "") {
      this.submitFormById("search-filter-form");
    }

    if (this.companyFullNameValue != "") {
      this.submitFormById("company-filters-form");
    }

    this.updateYearsFilter();
    this.updateEmployeeRange();
  }

  submitFormById(selected_id) {
    const form = document.getElementById(selected_id);
    if (form && is_form_submit) {
      form.requestSubmit();
      is_form_submit = false;
    }
  }

  disconnect() {
    is_form_submit = true;

    const filters = [
      "country_filter",
      "language_filter",
      "company_filter",
      "company_title",
      "university_filter",
      "job_title_filter",
      "degree_filter",
      "industry_filter",
      "state_filter",
      "skill_search",
    ];

    const destroySelect2 = (selector) => {
      const element = $(this.element).find(selector);
      if (element.length && $.fn.select2 && element.data("select2")) {
        element.select2("destroy");
      }
    };

    filters.forEach((filter) => {
      destroySelect2(`#${filter}`);
    });
  }

  updateEmployeeRange() {
    const yearsContainer = document.querySelector("[data-emplyees-range]");
    if (!yearsContainer) return;

    const range = yearsContainer.dataset.emplyeesRange;
    if (range == "" || range == "[]") return;

    const form_id = yearsContainer.dataset.formId;
    const form = document.getElementById(form_id);
    if (form) {
      form.requestSubmit();
    }
  }

  updateYearsFilter() {
    const yearsContainer = document.querySelector("[data-years-selected]");
    if (!yearsContainer) return;

    const selectedYears = yearsContainer.dataset.yearsSelected;
    if (selectedYears == "") return;

    const form = document.getElementById("search-filter-form");
    if (form && is_form_submit) {
      form.requestSubmit();
      is_form_submit = false;
    }
  }

  searchByName(event) {
    clearTimeout(this.timeout);
    const value = event.currentTarget.value;
    if (value.trim() == "") {
      $("#full_name_active_filter").removeClass("has-dot");
    }
    this.timeout = setTimeout(() => {
      const form = document.getElementById("search-filter-form");
      if (form) {
        form.requestSubmit();
        $("#full_name_active_filter").addClass("has-dot");
      }
    }, 600);
  }

  select2tags() {
    var tags = [];
    var placeholder = "Select an option";

    $(".select2multiple select").each(function (e) {
      const $select = $(this);
      $select
        .select2({
          placeholder: placeholder,
        })
        .on("select2:select", function (e) {
          const selector = e.target.parentNode.parentNode.nextElementSibling;
          if (!tags[selector.id]) {
            tags[selector.id] = [];
          }

          const __selector = format_selector(selector.id);
          isFilterActive(__selector, selector.id);

          prepare_tags_array(selector.id);
          const exists = tags[selector.id].some(
            (tag) => tag.id === e.params.data.id
          );
          if (!exists) {
            tags[selector.id].push({
              value: e.params.data.text,
              id: e.params.data.id,
              key: selector.id,
            });
          }

          updateCustomPlaceholder($select);
          displayTags(selector);
        })
        .on("select2:unselect", function (e) {
          const selector = e.target.parentNode.parentNode.nextElementSibling;
          const indexToRemove = tags[selector.id].findIndex(
            (tag) => tag.id === e.params.data.id
          );
          if (indexToRemove !== -1) {
            tags[selector.id].splice(indexToRemove, 1);
          }

          updateCustomPlaceholder($select);
          displayTags(selector);
        });
    });

    $(".tags-area").on("click", ".tag", function (e) {
      const selectorId = e.target.parentNode.dataset.element;
      const tagIdToRemove = e.target.parentNode.dataset.select;
      prepare_tags_array(selectorId);

      const __selector = format_selector(selectorId);
      isFilterActive(__selector, selectorId);

      const check_keys = Object.keys(tags);
      if (check_keys.length == 0) {
        return false;
      }

      const indexToRemove = tags[selectorId].findIndex(
        (tag) => tag.id === tagIdToRemove
      );

      if (indexToRemove !== -1) {
        tags[selectorId].splice(indexToRemove, 1);
      }
      const $select = $(`.select2multiple #${selectorId.replace("_tags", "")}`);
      let selectedValues = $select.val() || [];
      selectedValues = selectedValues.filter((val) => val !== tagIdToRemove);
      $select.val(selectedValues).trigger("change");

      e.target.parentNode.remove();

      return false;
    });

    function displayTags(element) {
      const check_keys = Object.keys(tags);
      if (check_keys.length == 0) {
        return false;
      }
      element.innerHTML = "";
      tags[element.id].forEach((tag) => {
        if (tag.key == element.id) {
          element.innerHTML += `<a href="javascript:void(0)" class="tag" data-select="${tag.id}" data-element="${element.id}">
          <span class="value">${tag.value}</span> <i class='anron-cross text-[15px] text-[#05123752]'></i>
          </a>`;
        }
      });
    }

    function prepare_tags_array(selectorId) {
      if (!tags[selectorId]) {
        tags[selectorId] = [];
      }
      const tag_items = document.getElementsByClassName("tag");
      if (tag_items.length > 0) {
        for (const item of tag_items) {
          const value = item.dataset.select;

          const find_value = tags[item.dataset.element]
            ? tags[item.dataset.element].find((tag) => tag.id === value)
            : undefined;
          if (!find_value && tags[item.dataset.element]) {
            tags[item.dataset.element].push({
              value: value,
              id: value,
              key: item.dataset.element,
            });
          }
        }
      }
    }

    function updateCustomPlaceholder($select) {
      const selectedValues = $select.val();
      const val =
        selectedValues && selectedValues.length > 0
          ? selectedValues[0] + " (" + selectedValues.length + ")"
          : placeholder;

      $select.next().find(".select2-selection__custom").html(val);
    }

    function format_selector(selector) {
      if (selector == "job_title_filter_tags") {
        return "job_title_active_filter";
      }

      if (selector == "skill_search_tags") {
        return "skills_active_filter";
      }

      if (selector == "language_filter_tags") {
        return "languages_active_filter";
      }

      if (selector == "language_filter_tags") {
        return "languages_active_filter";
      }

      if (selector == "university_filter_tags") {
        return "university_active_filter";
      }

      if (selector == "company_title_tags") {
        return "company_name_active_filter";
      }

      if (selector == "degree_filter_tags") {
        return "field_of_study_active_filter";
      }

      if (selector == "state_tags") {
        return "state_active_filter";
      }

      if (selector == "country_filter_tags") {
        return "location_active_filter";
      }

      if (selector == "state_filter_tags") {
        return "state_active_filter";
      }

      if (selector == "industry_filter_tags") {
        return "industry_active_filter";
      }

      return selector;
    }

    function isFilterActive(is_active, selector) {
      const elements = document.querySelectorAll(
        `[data-element="${selector}"]`
      );

      if (elements.length == 0) {
        $(`#${is_active}`).removeClass("has-dot");
      } else {
        $(`#${is_active}`).addClass("has-dot");
      }
    }
  }

  clearFilter(event) {
    event.preventDefault();
    event.stopPropagation();

    let filterKey = event.target.dataset.filterKey;
    if (
      [
        "skills",
        "location",
        "languages",
        "country_codes",
        "industry_filter",
        "country_filter",
        "company_name",
        "field_of_study",
        "university",
        "job_title",
      ].includes(filterKey)
    ) {
      const select =
        event.currentTarget.parentNode.nextElementSibling.querySelector(
          "select"
        );
      $(`#${select.id}`).val([]).trigger("change");
      $(`#${select.id}_tags`).html("");
    }

    if (["company_name_filter", "full_name"].includes(filterKey)) {
      if (filterKey == "full_name") {
        filterKey = "full_name_filter";
      }
      const element = document.getElementById(filterKey);
      if (element) {
        element.value = "";
        element.form.requestSubmit();
      }
    }

    if (
      [
        "profile_features",
        "score",
        "years_of_experience",
        "company_size",
        "no_of_employees",
        "contact_info",
      ].includes(filterKey)
    ) {
      $(`.${filterKey}`).prop("checked", false);
    }

    if (["location_radius"].includes(filterKey)) {
      const location_input = document.getElementById("search-location-input");
      if (location_input) {
        location_input.value = "";
      }

      const location_tags = document.getElementById("location_radius_tags");
      if (location_tags) {
        location_tags.innerHTML = "";
      }

      const location_data = document.getElementById("location_radius_data");
      if (location_data) {
        location_data.value = "";
      }

      const search_filter_form = document.getElementById("search-filter-form");
      if (search_filter_form) {
        search_filter_form.requestSubmit();
      }

      const radiusSlider = document.getElementById("db-rangeslider");
      if (radiusSlider) {
        radiusSlider.noUiSlider.set(5);
      }
    }

    const span_tag = event.currentTarget.previousElementSibling;
    if (span_tag) {
      span_tag.classList.remove("has-dot");
    }
  }

  toggleSearchFilter(event) {
    const contentWrapper = event.target?.parentNode?.querySelector(
      ".faq-drawer__content-wrapper"
    );
    if (contentWrapper) {
      if (event.target.checked) {
        contentWrapper.classList.add("overflow-visible!");
      } else {
        contentWrapper.classList.remove("overflow-visible!");
      }
    }
  }
}
