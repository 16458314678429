// app/javascript/controllers/details_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "betterContactEmail",
    "betterContactPhone",
    "fullDetail",
    "toggleButton",
  ];

  connect() {
    this.buttonText = "Show Details";
  }

  toggleDetails(event) {
    event.preventDefault();

    this.obfuscatedDetailTargets.forEach((target) =>
      target.classList.toggle("hidden")
    );
    this.fullDetailTargets.forEach((target) =>
      target.classList.toggle("hidden")
    );

    this.toggleButtonTarget.textContent === "Show Details"
      ? (this.toggleButtonTarget.textContent = "Hide Details")
      : (this.toggleButtonTarget.textContent = "Show Details");
  }

  toggleContactDetails(event) {
    event.preventDefault();

    if (event.currentTarget.dataset.type == "email") {
      if (this.betterContactEmailTarget.classList.contains("blur-xs")) {
        event.currentTarget.form.requestSubmit();
        event.currentTarget.textContent = "Hide Details";
      } else {
        event.currentTarget.textContent = "Show Details";
        this.betterContactEmailTarget.classList.add("blur-xs");
        this.betterContactEmailTarget.textContent = "us****@****om";
      }
    } else {
      if (this.betterContactPhoneTarget.classList.contains("blur-xs")) {
        event.currentTarget.form.requestSubmit();
        event.currentTarget.textContent = "Hide Details";
      } else {
        event.currentTarget.textContent = "Show Details";
        this.betterContactPhoneTarget.classList.add("blur-xs");
        this.betterContactPhoneTarget.textContent = "000000000000";
        this.betterContactPhoneTarget.classList.remove(
          "bg-[#05123752]",
          "rounded-[12px]",
          "px-2"
        );
      }
    }
  }
}
