import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="table-resize"
export default class extends Controller {
  connect() {
    this.resizeTable();
    this.draggableTableColumns();
  }

  resizeTable() {
    let startX,
      startWidth,
      $handle,
      pressed = false;

    const $table = $(".table-resizable");
    const $headers = $table.find("th>div:not(.resize-bar)");
    const $resizeBars = $table.find(".resize-bar");

    $resizeBars.on("mousedown", function (event) {
      const index = $(this).parent().index();
      $handle = $headers.eq(index);
      pressed = true;
      startX = event.pageX;
      startWidth = $handle.width();

      $handle.css("transition", "none");

      event.preventDefault();
    });

    function resizeHandler(event) {
      if (pressed) {
        requestAnimationFrame(() => {
          const newWidth = startWidth + (event.pageX - startX);
          $handle.width(newWidth);
        });
      }
    }

    $(document).on("mousemove", resizeHandler);
    $(document).on("mouseup", function () {
      if (pressed) {
        pressed = false;
        $handle.css("transition", "width 0.2s ease");
      }
    });

    $table.find("thead").on("dblclick", function () {
      $headers.css({
        minWidth: "",
        width: "",
      });
    });
  }

  draggableTableColumns() {
    $(".table-draggable").dragableColumns({
      drag: true,
      dragClass: "drag",
      overClass: "over",
      movedContainerSelector: ".dnd-moved",
    });

    const headers = document.querySelectorAll(".table-draggable th");

    headers.forEach((header, index) => {
      header.addEventListener("dragstart", (event) => {
        if (event.target.innerText === "Full Name") {
          event.preventDefault();
          return;
        }
      });

      header.addEventListener("drop", (event) => {
        if (index == 0) {
          event.preventDefault();
          return;
        }

        const headers_array = this.getTableHeaders();
        const form = document.getElementById("shortlist-ordering-form");
        if (form) {
          document.getElementById("shortlist-ordering-field").value =
            JSON.stringify(headers_array);
          form.requestSubmit();
        }
      });
    });
  }

  getTableHeaders() {
    const table = document.querySelector(".table-draggable");

    if (!table) {
      console.error("Table with the class .table-draggable not found.");
      return [];
    }

    const headers = table.querySelectorAll("thead .table-heading");
    const headerTexts = Array.from(headers).map((th) => {
      return th.textContent.trim().toLowerCase().replace(/\s+/g, "_");
    });

    return headerTexts;
  }
}
