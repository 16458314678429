import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="snackbar"
export default class extends Controller {
  connect() {
    const snackbarContent = this.element.dataset.content;
    if (snackbarContent != null || snackbarContent != "") {
      setTimeout(() => {
        this.hideSnackbar();
      }, 2500);
    }
  }

  hideSnackbar() {
    const snackbar = this.element;
    if (snackbar) {
      this.element.classList.remove("translate-x-0");
      this.element.classList.add("translate-x-full");
      this.element.remove();
    }
  }
}
