export function openModal(element) {
  const modalElement =
    typeof element === "string" ? document.getElementById(element) : element;

  if (modalElement) {
    modalElement.classList.remove("hidden");
  }
}

export function closeModal(element) {
  const modalElement =
    typeof element === "string" ? document.getElementById(element) : element;

  if (modalElement) {
    modalElement.classList.add("hidden");
  }
}

export function closeOnOutsideClick(
  currentSelectorElement,
  DropdownElement,
  currentEventTarget
) {
  if (!currentSelectorElement || !DropdownElement || !currentEventTarget) {
    console.log("Error: missing required parameters");
    return;
  }

  if (
    currentSelectorElement &&
    DropdownElement &&
    !currentSelectorElement.contains(currentEventTarget) &&
    !DropdownElement.contains(currentEventTarget)
  ) {
    DropdownElement.classList.add("hidden");
  }
}

export function closeOnOutsideClickAndToggleShow(
  currentSelectorElement,
  DropdownElement,
  currentEventTarget
) {
  if (!currentSelectorElement || !DropdownElement || !currentEventTarget) {
    console.log("Error: missing required parameters");
    return;
  }

  if (
    currentSelectorElement &&
    DropdownElement &&
    !currentSelectorElement.contains(currentEventTarget) &&
    !DropdownElement.contains(currentEventTarget)
  ) {
    DropdownElement.classList.remove("show");
  }
}
