// portal/visible-dropdown/visible_dropdown_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["visibleFields"];

  connect() {}

  renderFields(fields) {
    fields.forEach((field) => {
      const listItem = document.createElement("li");
      listItem.innerHTML = `
        <a href="javascript:void(0)" class="flex items-center gap-[10px] checkbox">
          <input type="checkbox" id="star-${field.id}" ${
        field.visible ? "checked" : ""
      }>
          <label for="star-${
            field.id
          }" class="font-normal text-[14px] leading-5 not-italic font-text_font text-text_color">
            ${field.name}
          </label>
        </a>
      `;
      this.menuTarget.appendChild(listItem);
    });
  }
}
