import { Controller } from "@hotwired/stimulus";
import { initSelect2, destroySelect2 } from "../components/select2";
import { openModal, closeModal } from "../components/modal";
// Connects to data-controller="account-settings"
export default class extends Controller {
  static targets = ["deleteUserModal"];

  connect() {
    initSelect2("user_time_zone");
  }

  disconnect() {
    destroySelect2("user_time_zone");
  }

  showPassword(event) {
    const element = event.currentTarget;

    const input = element.previousElementSibling;
    if (input.type === "password") {
      input.type = "text";
      element.classList.add("icon-eye-off");
      element.classList.remove("icon-eye");
    } else {
      input.type = "password";
      element.classList.add("icon-eye");
      element.classList.remove("icon-eye-off");
    }
  }

  openDeleteUserModal() {
    if (!this.hasDeleteUserModalTarget) return;
    openModal(this.deleteUserModalTarget);
  }

  closeDeleteUserModal() {
    if (!this.hasDeleteUserModalTarget) return;
    closeModal(this.deleteUserModalTarget);
  }
}
