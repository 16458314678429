import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="portal--navbar"
export default class extends Controller {
  static targets = ["headerLayer", "navbarDropdown"];

  connect() {
    document.addEventListener("click", function (event) {
      const dropDownButton = document.getElementById("navbarDropDownAction");
      const dropDown = document.getElementById("navbarDropdown");
      if (
        dropDownButton &&
        dropDown &&
        !dropDownButton.contains(event.target) &&
        !dropDown.contains(event.target)
      ) {
        dropDown.classList.remove("show");
      }
    });
  }

  toggleHeaderOne() {
    if (!this.hasHeaderLayerTarget) {
      return false;
    }
    if (this.headerLayerTarget.classList.contains("-translate-y-full")) {
      this.headerLayerTarget.classList.remove("-translate-y-full");
      this.headerLayerTarget.classList.add("translate-y-0");
    } else {
      this.headerLayerTarget.classList.remove("translate-y-0");
      this.headerLayerTarget.classList.add("-translate-y-full");
    }
  }

  toggleNavbarDropdown(event) {
    if (!this.hasNavbarDropdownTarget) {
      return;
    }
    this.navbarDropdownTarget.classList.contains("show")
      ? this.navbarDropdownTarget.classList.remove("show")
      : this.navbarDropdownTarget.classList.add("show");
  }
}
