import { Controller } from "@hotwired/stimulus";
import { openModal, closeModal } from "../../components/modal";
import { toast } from "../../components/snackbar";
let single_profile_id = null;
export default class extends Controller {
  static targets = [
    "statusButton",
    "statusMenu",
    "phoneButton",
    "phoneMenu",
    "emailButton",
    "emailMenu",
    "form",
    "input",
    "addToShortlistForm",
    "inviteLinkInput",
    "iframeText"
  ];

  connect() {
    this.boundHandleClickOutside = this.handleClickOutside.bind(this);
    this.boundCloseDropdown = this.closeDropdown.bind(this);

    document.addEventListener("click", this.boundHandleClickOutside);
    document.addEventListener("closeDropdowns", this.boundCloseDropdown);
  }

  disconnect() {
    document.removeEventListener("click", this.boundHandleClickOutside);
    document.removeEventListener("closeDropdowns", this.boundCloseDropdown);
  }

  closeDropdown() {
    const items = this.element.querySelectorAll(".shortlist-dropdown");
    if (items) {
      items.forEach((item) => {
        item.classList.add("hidden");
      });
    }
  }

  handleClickOutside(event) {
    if (!this.element.contains(event.target)) {
      this.closeDropdown();
    }
  }

  enrichContact(event) {
    const data = event.currentTarget.dataset;
    if (data && data.contact === "true") {
      event.preventDefault();
    }
  }

  addCustomEmail(event) {
    clearTimeout(this.timeout);

    let currentEvent = event.currentTarget;
    const value = currentEvent.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (value.trim() == "") {
      return false;
    }
    this.timeout = setTimeout(() => {
      if (emailRegex.test(value)) {
        currentEvent.form.requestSubmit();
      }
    }, 1000);
  }

  addCustomPhone(event) {
    clearTimeout(this.timeout);
    let currentEvent = event.currentTarget;
    const value = currentEvent.value;

    const phoneRegex = /^\+?\d+$/;

    if (value.trim() === "" || !phoneRegex.test(value)) {
      return false;
    }

    this.timeout = setTimeout(() => {
      currentEvent.form.requestSubmit();
    }, 1000);
  }

  removeShortlistContact(event) {
    const group = event.currentTarget.closest(".db-form-group");
    const form = event.currentTarget.closest("form");
    if (group && form) {
      group.remove();
      form.requestSubmit();
    }
  }

  closeShortlistModal(event) {
    event.preventDefault();
    closeModal("manage-shortlist-modal");
    this.resetCheckboxes();
  }

  removeNewForm() {
    const target = document.getElementById("add-new-shortlist");
    if (target) {
      target.innerHTML = "";
    }
  }

  openShortlistedModal() {
    single_profile_id = null;
    openModal("manage-shortlist-modal");
  }

  addPeopleToShortlist(event) {
    event.preventDefault();
    const errorMessage = document.getElementById("showErrorMessage");
    const shortlist_ids = this.getCheckedValues("shortlist_checkbox");

    if (shortlist_ids.length == 0) {
      errorMessage.textContent = "Please select atleast one shortlist";
      return;
    }

    if (single_profile_id === null) {
      const form = this.addToShortlistFormTarget;
      if (form) {
        const profile = document.querySelectorAll(`.profile_select`);
        const profile_ids = Array.from(profile).map((p) =>
          p.getAttribute("data-id")
        );

        if (profile_ids.length == 0) {
          errorMessage.textContent = "Please select atleast one profile";
          return;
        } else {
          document.getElementById("shortlisted_profile_ids").value =
            JSON.stringify(profile_ids);
          document.getElementById("assigned_shortlist_ids").value =
            JSON.stringify(shortlist_ids);
          form.requestSubmit();
        }
      }
    } else {
      if (single_profile_id == null) {
        errorMessage.textContent = "profile not selected";
        return;
      }
      const form = document.getElementById("shortlistPersonForm");
      if (form) {
        document.getElementById("assign_shortlisted_id").value =
          JSON.stringify(shortlist_ids);
        document.getElementById("shortlisted_person_id").value =
          single_profile_id;
        form.requestSubmit();
      }
      closeModal("manage-shortlist-modal");
    }
    errorMessage.textContent = "";
  }

  getCheckedValues(targetClass) {
    const checkboxes = document.querySelectorAll(`.${targetClass}`);
    return Array.from(checkboxes)
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.getAttribute("data-id"));
  }

  assignShortlist(event) {
    single_profile_id = event.currentTarget.dataset.id;
    this.resetCheckboxes();
    openModal("manage-shortlist-modal");
  }

  onEnterShortlistSubmit(event) {
    if (event.key === "Enter" && event.currentTarget.value !== "") {
      event.preventDefault();
      const input = document.getElementById("add-new-shortlist");
      input.innerHTML = "";
    }
  }

  submitShortlistModalForm(event) {
    const inputField = event.currentTarget.parentNode.querySelector(
      'input[id="shortlist_name"]'
    );
    const form = document.getElementById("shortlistNewEditForm");

    if (inputField.value == "") {
      event.preventDefault();
      form.reportValidity();
      return;
    }

    if (form) {
      form.requestSubmit();
      const input = document.getElementById("add-new-shortlist");
      input.innerHTML = "";
    }
  }

  resetCheckboxes() {
    const checkboxes = document.querySelectorAll(".shortlist_checkbox");
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
    const errorMessage = document.getElementById("showErrorMessage");
    if (errorMessage) {
      errorMessage.textContent = "";
    }
  }

  openImportCSVModal() {
    openModal("import-csv-shortlist-modal");
    closeModal("shortlist-contact-popup");
  }

  closeImportCSVModal() {
    closeModal("import-csv-shortlist-modal");
  }

  showImportLoader(event) {
    const file = document.getElementById("csv-file-input").files[0];

    if (!file) {
      const errorMessage = document.getElementById("inputFieldError");
      errorMessage.textContent = "Please select a file";
      event.preventDefault();
      return;
    }

    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (fileExtension !== "csv") {
      const errorMessage = document.getElementById("inputFieldError");
      errorMessage.textContent = "Please upload a CSV file";
      event.preventDefault();
      return;
    }

    openModal("import-loader-popup");
    closeModal("import-csv-shortlist-modal");
  }

  copyInviteLinkUrl() {
    const input = this.inviteLinkInputTarget;
    if (input) {
      this.copyToClipboard(
        input.value,
        "Invite link copied to clipboard",
        "Failed to copy invite link to clipboard"
      );
    }
  }

  copyShareShortlistLink(event) {
    const shareLink = event.currentTarget.dataset.shareLink;

    if (shareLink) {
      this.copyToClipboard(
        shareLink,
        "Shortlist Share link copied to clipboard",
        "Failed to copy shortlist share link to clipboard"
      );
    }
  }

  copyToClipboard(text, successMessage, errorMessage) {
    if (text) {
      navigator.clipboard.writeText(text)
        .then(() => {
          toast(successMessage, "success");
        })
        .catch(() => {
          toast(errorMessage, "error");
        });
    }
  }

  openShortlistModal() {
    single_profile_id = null;
    openModal("manage-shortlist-modal");
    const shortlistDropdown = document.getElementById("shortlist_all_filters");
    if (shortlistDropdown) {
      shortlistDropdown.classList.add("hidden");
    }
  }

  openContactModal() {
    openModal("shortlist-contact-popup");
  }

  generateEmbedCode(url) {
    return `
    <div class="shortlist-widget">
      <iframe src="${url}"
              width="100%"
              height="800"
              frameborder="0"
              style="border: none;">
      </iframe>
    </div>`;
  }

  embedCodeModal(event) {
    const url = event.currentTarget.dataset.embedLink;
    const embedCode = this.generateEmbedCode(url);

    this.iframeTextTarget.innerHTML = Prism.highlight(
      embedCode,
      Prism.languages.html,
      "html"
    );

    // Remove the hidden class to show the modal
    this.element.classList.remove("hidden");
    openModal("embed-code-popup")
  }

  copyEmbedCode() {
    const url = event.currentTarget.dataset.embedLink;
    if (url){
      const embedCode = this.generateEmbedCode(url);
      this.copyToClipboard(
        embedCode,
        "Embed code copied to clipboard!",
        "Failed to copy embed code"
      );
    }
  }
}
