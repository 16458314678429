export function toast(title = "no title provided", type = "success") {
  const content = prepareSnackBarHTML(title, type);
  const portal_snackbar = document.getElementById("portal_snackbar");
  if (!portal_snackbar) {
    return;
  }

  portal_snackbar.innerHTML = content;
}

const prepareSnackBarHTML = (title, type) => {
  let icon = ``;
  if (type == "error") {
    icon = `<span class="inline-flex w-[32px] h-[32px] p-[2px] items-center justify-center rounded-[50%] cursor-pointer bg-[#F04438]">
              <i class="anron-cross text-[#fff] text-[18px]"></i>
            </span>`;
  }
  if (type == "success") {
    icon = `<span class="inline-flex w-[32px] h-[32px] p-[2px] items-center justify-center rounded-[50%] cursor-pointer bg-[#17B26A]">
              <i class="anron-check text-[#fff] text-[18px]"></i>
            </span>`;
  }
  return `<div data-controller="snackbar" class="fixed top-0 left-0 w-full flex items-end px-4 py-6 sm:items-start sm:p-6 translate-x-0 transition-all z-[9999]">
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <div class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-[40px] bg-white shadow-shadow_twelve p-[12px]">
        <div class="flex justify-center items-center">
          <div class="shrink-0"> ${icon}</div>
          <div class="ml-3 w-0 flex-1 pt-0.5">
            <p class="text-[14px] font-normal leading-[16px] font-text_font text-heading_color"> ${title} </p>
          </div>
          <div class="ml-4 flex shrink-0">
            <button type="button" data-action="click->snackbar#hideSnackbar" class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:outline-hidden">
              <i class="anron-cross text-[#666D80] text-[16px] cursor-pointer"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>`;
};
